import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Strix | François Deguire' />
    <ProjectPage
      name='Strix'
      description={`
      The Can-Am Strix is an electric microcar made for North American cities. 
      It has been designed to meet the travel needs of young urban workers who 
      want an option adapted to their lifestyle. Its short length will allow 
      cities to review their parking regulations in order to give back space to 
      their citizens. The greater height and width than the norm for sub-compact 
      cars makes the car well suited to our winter climates. The Strix’s 
      innovative rear platform integrates two LinQ-e connected modules that can be 
      used as a shopping bag, refrigerated compartments, ski/bike racks and even 
      auxiliary batteries to extend the vehicle’s range. As for the interior, it 
      aims to make the technology more discreet in the cabin by using LED arrays 
      and head-up displays in a monolithic fabric dashboard.
      `}
      meta={`
        For Université de Montréal<br>
        In 2020<br><br>
        In partnership with BRP
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    strix1: file(relativePath: { eq: "strix/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix2: file(relativePath: { eq: "strix/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix3: file(relativePath: { eq: "strix/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix4: file(relativePath: { eq: "strix/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix5: file(relativePath: { eq: "strix/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix6: file(relativePath: { eq: "strix/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
